'use client'

import {
	type ReactNode,
	useEffect,
	useState,
	useSyncExternalStore,
} from 'react'
import { createPortal } from 'react-dom'

import styles from './OverTopLayer.module.scss'

export const OVER_TOP_LAYER_ID = 'over-top-layer'

const overTopLayerStore = {
	layer: null as HTMLDivElement | null,
	subscribers: [] as (() => void)[],
	subscribe: (fn: () => void) => {
		overTopLayerStore.subscribers.push(fn)
		return () => {
			// Unsubscribe
			overTopLayerStore.subscribers = overTopLayerStore.subscribers.filter(
				(subscriber) => subscriber !== fn
			)
		}
	},
	getSnapshot: () => {
		return overTopLayerStore.layer
	},
	dispatchLayer: (layer: HTMLDivElement) => {
		overTopLayerStore.layer = layer
		overTopLayerStore.subscribers.forEach((fn) => fn())
	},
}

export function OverTopLayerModal() {
	return (
		<div
			id={OVER_TOP_LAYER_ID}
			className={styles.overTopLayer}
			ref={(ref: HTMLDivElement) => {
				overTopLayerStore.dispatchLayer(ref)
			}}
		/>
	)
}

export function OverTopLayer({ children }: { children: ReactNode }) {
	const [onClient, setOnClient] = useState(false)
	const overTargetLayer = useSyncExternalStore<HTMLDivElement | null>(
		overTopLayerStore.subscribe,
		overTopLayerStore.getSnapshot
	)

	useEffect(() => {
		setOnClient(true)
	}, [])

	if (!onClient) {
		return children
	}

	const targetLayer = overTargetLayer || document.body // Over all, modal rendered on #topLayer included // Over all elements, header included, but below modals

	if (targetLayer === document.body) {
		console.warn(
			'OverTopLayer: No target layer found, using document.body. This element possibly be shown over header'
		)
	}

	return createPortal(<>{children}</>, targetLayer)
}
