import { type MutableRefObject, useEffect } from 'react'

const isClickOutsideDialog = (
	e: MouseEvent,
	dialogDimensions: DOMRect | undefined
) => {
	if (!dialogDimensions) {
		return false
	}

	return (
		e.clientX < dialogDimensions.left ||
		e.clientX > dialogDimensions.right ||
		e.clientY < dialogDimensions.top ||
		e.clientY > dialogDimensions.bottom
	)
}

const isPointerInteraction = (e: MouseEvent): boolean =>
	e.clientX + e.clientY !== 0

export const useModalListeners = ({
	dialogRef,
	preventClose,
	close,
}: {
	dialogRef: MutableRefObject<HTMLDialogElement | null>
	preventClose: boolean
	close: () => void
}) => {
	const handleClickEvent = (e: MouseEvent) => {
		if (preventClose || !isPointerInteraction(e)) {
			return
		}

		const dialogDimensions = dialogRef.current?.getBoundingClientRect()

		if (!isClickOutsideDialog(e, dialogDimensions)) {
			return
		}

		close()
	}

	const handleEscEvent = (e: Event) => {
		e.preventDefault()
		if (!preventClose) {
			close()
		}
	}

	useEffect(() => {
		if (dialogRef.current) {
			// Listener to close the modal when clicking outside of it.
			// When onKeyPress is triggered inside a Modal, another click event is executed on the (0,0) coordinate of the DOM.
			// We need to differentiate this (sided) click event from the proper one done by the user with an actual pointer.
			dialogRef.current.addEventListener('click', handleClickEvent)

			// Listener to close the modal when pressing the ESC key.
			dialogRef.current.addEventListener('cancel', handleEscEvent)
		}

		return () => {
			if (dialogRef.current) {
				dialogRef.current.removeEventListener('click', handleClickEvent)
				dialogRef.current.removeEventListener('cancel', handleEscEvent)
			}
		}
	}, [dialogRef.current, preventClose, close])
}
